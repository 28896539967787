<template>
  <div>
    <cabecalho-pagina
      ref="cabecalho-padrao-filtro-formulas"
      :titulo="$t('modulos.formula.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.formula.titulos.novo')"
      :filtro-pre-definido="'Sim'"
      :sem-botao-ok="!buscarPermissao('Formula', 'Inserir')"
      :sem-filtro="!buscarPermissao('Formula', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('Formula', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao-prime-vue
      ref="tabela"
      v-model="tabela.selecionados"
      class="mt-2"
      :colunas="tabela.colunas"
      :dados="tabela.dados"
      :mostrar-acoes="true"
      :mostrar-seletor="buscarPermissao('Formula', 'Deletar')"
      filtros-com-colunas
      :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      :pagina="tabela.paginaAtual"
      @paginar="listarRegistros"
      @filtrarGlobal="filtrarGlobal"
      @filtroGlobal="filtroGlobal"
      @nova-ordenacao="ordenacaoColuna"
    >
      <template v-slot:seletor="{ slotProps }">
        <v-simple-checkbox
          v-model="slotProps.data.isSelected"
          :disabled="slotProps.data.flagSistema"
          @input="selecionarTabela(slotProps.data, slotProps.data.isSelected)"
        />
      </template>
      <template v-slot:acoes="{ slotProps }">
        <dropdown-padrao text color="secondary">
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <dropdown-padrao-item
            v-if="buscarPermissao('Formula', 'Editar')"
            @click="abrirEditar(slotProps.data)"
          >
            {{ $t('geral.botoes.editar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Formula', 'Duplicar')"
            @click="duplicarItem(slotProps.data)"
          >
            {{ $t('geral.botoes.duplicar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="buscarPermissao('Formula', 'Visualizar')"
            @click="visualizar(slotProps.data)"
          >
            {{ $t('geral.botoes.visualizar') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="
              slotProps.data.mensurando == true &&
              buscarPermissao('Formula', 'Inserir')
            "
            @click="novaFormulaFilha(slotProps.data)"
          >
            {{ $t('modulos.formula.botoes.nova_formula_filha') }}
          </dropdown-padrao-item>
          <dropdown-padrao-item
            v-if="
              slotProps.data.mensurando == true &&
              buscarPermissao('Formula', 'Visualizar')
            "
            @click="visualizarFormulasFilhas(slotProps.data)"
          >
            {{ $t('modulos.formula.botoes.visualizar_formula_filha') }}
          </dropdown-padrao-item>
        </dropdown-padrao>
      </template>
    </tabela-padrao-prime-vue>
    <template>
      <novo-simbolo-duplicar-modal
        ref="novo-simbolo-duplicar-modal"
        @atualizar-formulas="
          listarRegistros(tabela.paginaAtual, tabela.porPagina)
        "
      />
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import helpers from '@/common/utils/helpers';
import CabecalhoPagina from '@/components/layout/CabecalhoPagina.vue';
import FormulaService from '@/common/services/cadastros/FormulaService.js';
import Filtro from './filtro.vue';
import NovoSimboloDuplicarModal from './components/modais/NovoSimboloDuplicarModal.vue';

export default {
  components: {
    CabecalhoPagina,
    Filtro,
    NovoSimboloDuplicarModal,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.formula.tabela.codigo'),
            sortable: true,
          },
          {
            value: 'simbolo',
            text: this.$t('modulos.formula.tabela.simbolo'),
            sortable: true,
          },
          {
            value: 'nome',
            text: this.$t('modulos.formula.tabela.nome'),
            sortable: false,
          },
          {
            value: 'mensurando',
            text: this.$t('modulos.formula.tabela.mensurando'),
            sortable: false,
            formatter: (v) => {
              return helpers.BoleanoEnum.find((el) => el.value === v)?.text;
            },
          },
          {
            value: 'variaveisQuantidade',
            text: this.$t('modulos.formula.tabela.qtdVariaveis'),
            sortable: false,
          },
          {
            value: 'formulaMensurando',
            text: this.$t('modulos.formula.tabela.formulaMensurando'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      dadosFiltroPreDefinido: {
        text: 'Sim',
      },
      colunaOrdenada: '',
    };
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Formula', 'Listar');
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  computed: {
    ...mapGetters('Autenticacao', ['participanteLogadoConta']),
    podeExcluir() {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    if (Object.keys(this.filtroAplicado).length === 1 && this.filtroAplicado.filter === '') {
      this.filtroAplicado.mensurando = true;
    }
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.formula.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros(
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.tabela.paginaAtual = paginaAtual;
      this.tabela.porPagina = porPagina;
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: this.tabela.porPagina ?? porPagina,
        sort: this.colunaOrdenada,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.tabela.dados = [];
      FormulaService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros() {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        FormulaService.excluir(this.tabela.selecionados[0].id)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(this.$t(`modulos.formula.exclusao_sucesso`));
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo() {
      this.$router.push({ name: 'formula-novo' });
    },
    abrirEditar(item) {
      this.$router.push({
        name: 'formula-editar',
        params: { id: item.id },
      });
    },
    novaFormulaFilha(item) {
      this.$router.push({
        name: 'nova-formula-filha',

        query: {
          idFormulaMensurando: item.id,
        },
      });
    },
    visualizarFormulasFilhas(item) {
      this.filtroAplicado = { filter: '' };
      this.$refs.tabela?.limparValueFiltroGlobal();
      this.filtroAplicado['formulaMensurando'] = item.codigo;
      this.tabela.paginaAtual = 1;
      this.$refs['cabecalho-padrao-filtro-formulas'].aplicarFiltro(
        this.filtroAplicado
      );

      this.$refs.tabela.atualizarPaginaAtual();
    },
    visualizar(item) {
      this.$router.push({
        name: 'formula-visualizar',
        params: { id: item.id },
      });
    },
    duplicarItem(item) {
      if (!item.flagSistema && !item.mensurando) {
        this.$refs['novo-simbolo-duplicar-modal'].abrirModal(item);
        return;
      }

      this.$store.dispatch('Layout/iniciarCarregamento');
      if (item.id) {
        FormulaService.duplicar(item.id)
          .then(() => {
            this.listarRegistros(
              this.tabela.paginaAtual,
              this.tabela.porPagina
            );
            this.toastSucesso(this.$t(`modulos.formula.duplicada_sucesso`));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    selecionarTabela(item, selecionado) {
      if (!selecionado) {
        this.tabela.selecionados.splice(
          this.tabela.selecionados.indexOf(
            this.tabela.selecionados.find((el) => el.id == item.id)
          ),
          1
        );
      } else {
        this.tabela.selecionados.push(item);
      }
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    ordenacaoColuna(ordem) {
      this.colunaOrdenada = ordem;
      this.listarRegistros()
    },
  },
};
</script>
<style lang="scss">
.alinhar-direita {
  display: flex;
  justify-content: right;
}
</style>

<template>
  <modal-padrao
    ref="modal-duplicar-formulas"
    :titulo="$t('modulos.formula.modal_duplicar.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    :titulo-cancelar="$t('geral.botoes.cancelar')"
    @ok="duplicarFormula"
  >
    <v-form
      ref="form"
      class="row"
    >
      <input-text
        v-model="novoSimbolo"
        :label="$t('modulos.formula.modal_duplicar.formulario.novo_simbolo')"
        class="col-12"
        :regras-personalizadas="regraSimboloNaoPodeSerIgual()"
        obrigatorio
      />
    </v-form>
  </modal-padrao>
</template>
<script>
import FormulaService from '@/common/services/cadastros/FormulaService.js';

export default {
  data: function () {
    return {
      id: null,
      novoSimbolo: null,
      simbolo: null,
    };
  },
  methods: {
    abrirModal: function (formula) {
      this.$refs['modal-duplicar-formulas'].abrirModal();
      this.novoSimbolo = null;
      this.id = formula.id;
      this.simbolo = formula.simbolo;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    duplicarFormula: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      if (this.id && this.novoSimbolo) {
        FormulaService.duplicar(this.id, { novoSimbolo: this.novoSimbolo })
          .then(() => {
            this.toastSucesso(this.$t(`modulos.formula.duplicada_sucesso`));
            this.$refs['modal-duplicar-formulas'].fecharModal();
            this.$emit('atualizar-formulas');
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      }
    },
    regraSimboloNaoPodeSerIgual: function () {
      if (!this.novoSimbolo) return [];

      let regraSimbolo = (v) => {
        let valor = v.trim().toLowerCase();
        if (valor === this.simbolo.trim().toLowerCase()) {
          return this.$t(
            'modulos.formula.modal_duplicar.simboloNaoPodeSerIgual'
          );
        }

        return true;
      };
      return [regraSimbolo];
    },
  },
};
</script>

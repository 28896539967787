<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-12 col-md-6"
      :label="$t('modulos.formula.filtro.codigo')"
      :placeholder="$t('modulos.formula.filtro.codigo')"
      em-filtro
    />
    <input-text
      v-model="filtro.nome"
      class="col-12 col-md-6"
      :label="$t('modulos.formula.filtro.nome')"
      :placeholder="$t('modulos.formula.filtro.nome')"
      em-filtro
    />
    <input-select
      v-model="filtro.mensurando"
      :options="opcoes.booleano"
      class="col-12 col-md-6"
      :label="$t('modulos.formula.filtro.mensurando')"
      :placeholder="'Selecione'"
      em-filtro
      clearable
      retornar-objeto
    />

    <input-text
      v-model="filtro.formulaMensurando"
      class="col-12 col-md-6"
      :label="$t('modulos.formula.filtro.formulaMensurando')"
      :placeholder="$t('modulos.formula.filtro.formulaMensurando')"
      em-filtro
    />
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
    };
  },
};
</script>
